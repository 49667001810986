import axios from './api/api';
const TOKEN_KEY = 'access_token';

export default {
  login(email: string, password: string) {
    return axios.post('/login', { email, password })
      .then(response => {
        const { access_token } = response.data;
        localStorage.setItem(TOKEN_KEY, access_token);
        return response.data;
      });
  },

  logout() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  isAuthenticated() {
    const token = this.getToken();
    return !!token;
  },
};

import axios from 'axios';

// const baseURL = 'http://127.0.0.1:5000/';
const baseURL = 'https://autofuel.gr/api/prod/'

// Create an Axios instance with the base URL
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for JWT token
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
